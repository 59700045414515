<template>
    <div class="section-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <b-overlay :show="loading">
            <div class="form-wrapper clone-wrapper">
                <b-card class="total-clone" :title="$t('teaGardenDashboard.clone_bi_clone_management')">
                    <b-row no-gutters>
                        <b-col>
                            <div class="clone-card">
                                <div class="content">
                                    <div>
                                        <p>{{ $t('globalTrans.total') }} {{$t('teaGardenBtriService.clone')}}</p>
                                        <h3>{{ $n(totCloneQty) }}</h3>
                                    </div>
                                    <img src="../../../../../assets/images/clone.png" class="img-fluid" alt="">
                                </div>
                                <div class="clone-types">
                                    <marquee>
                                        <ul>
                                            <li v-for="(item, index) in cloneList" :key="index">
                                                <a href="#">{{ currentLocale === 'en' ? item.clone_name_en : item.clone_name_bn }}</a>
                                            </li>
                                        </ul>
                                    </marquee>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
            <b-card>
                <div id="chart" class="px-3">
                    <!-- <h5 class="my-2">{{$t('teaGardenConfig.clone_name')}}</h5> -->
                    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                    <!-- <div class="tea-chart-number">
                        <p><i>{{ $t('teaGardenBtriService.clone') }}</i></p>
                    </div> -->
                    <!-- <p class="tea-chart-year"><i>{{ $t('teaGarden.stock_quantity') }}</i></p> -->
                </div>
                </b-card>
        </b-overlay>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
    mixins: [teaGardenService],
    components: {},
    data () {
        return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        errors: [],
        totCloneQty: 0,
        cloneList: [],
        newSeriesData: [],
        categories: [],
        categoriesBn: []
        }
    },
    created () {
        this.loadData()
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        loading () {
            return this.$store.state.commonObj.loading
        },
        series () {
                return [{
                    name: `${this.$t('teaGarden.stock_quantity')}`,
                    data: this.newSeriesData
                }
            ]
            },
        chartOptions () {
                return {
                    chart: {
                        type: 'bar',
                        height: 450
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            borderRadius: 4
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                        colors: ['#fff']
                        }
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['#fff']
                    },
                    xaxis: {
                        categories: this.cloneList.map(item => this.currentLocale === 'en' ? item.clone_name_en : item.clone_name_bn),
                        title: {
                            text: this.$t('teaGarden.stock_quantity')
                        }
                    },
                    yaxis: {
                        title: {
                            text: this.$t('teaGardenConfig.clone_name')
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7']
                }
            }
    },
    methods: {
        searchData () {
        this.loadData()
        },
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/clone-bi-clone', this.search)
            if (result.success) {
                if (result.data) {
                    this.cloneList = result.data
                    this.totCloneQty = result.data[0].totCloneQty
                    const newSeriesArr = []
                    result.data.forEach(item => {
                        if (item.stock_quantity === null) {
                            item.stock_quantity = 0
                        }
                        newSeriesArr.push(item.stock_quantity)
                    })
                    this.newSeriesData = newSeriesArr
                }
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
    }
}
</script>

<style>
.clone-wrapper .clone-card {
    color: aliceblue;
    padding: 1rem;
    background: linear-gradient(90deg, rgb(27 44 64) 0%, rgb(21 93 155) 100%);
}

.clone-wrapper .clone-card .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.clone-wrapper .clone-card .content p{
    font-size: 28px;
}
.clone-wrapper .card .card-body .card-title{
    color: #fff!important;
    background-color: #15263a!important;
}
.clone-wrapper .clone-card img{
    height: 100px;
}
.clone-wrapper .clone-types{
    padding: 0.2rem 1rem;
    border-radius: 50px;
    box-shadow: rgb(19 113 255 / 36%) 0px 30px 60px -12px inset, rgb(255 255 255 / 60%) 0px 18px 36px -18px inset;
}
.clone-wrapper .clone-types ul{
    display: flex;
    align-items: center;
    height: 30px;
}
.clone-wrapper .clone-types ul li{
    margin: 0 10px;
}
.clone-wrapper .clone-types ul li a{
    color: #fff;
}
</style>
